import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HeaderNav from "../components/header-nav"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.siteTitle
  const footerMetadata = data.site.siteMetadata.homeOptions

  return (
    <div class="fullheight">
      <Layout location={location} title={siteTitle} footer={footerMetadata}>
        <header>
          <HeaderNav bannerColor="BMO Blue" />
        </header>
        <Seo title="404: Not Found" />
        <main>
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist.</p>
        </main>
      </Layout>
    </div>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        description
        siteUrl
        siteTitle
        homeOptions {
          footer
          header
        }
      }
    }
  }
`
